<template>
  <div class="changes-history">
    <spinner v-if="loading" />
    <template v-else>
      <template v-if="changes">
        <b-row v-if="book && book.is_changed" class="mb-4">
          <b-col>
            <ebp-button
              variant="dark"
              :loading="acknowledging"
              @click="acknowledge"
            >
              <i class="bx bx-info-circle"></i>
              {{ $t("acknowledge-changes") }}</ebp-button
            >
          </b-col>
        </b-row>
        <ul class="timeline">
          <li
            class="timeline-item"
            v-for="(group, key) in changes"
            :key="key"
            v-b-toggle="`collapse-${key}`"
          >
            <div class="timeline-summary">
              <i class="bx bx-right-arrow-circle"></i>
              <p class="date mb-0">
                {{ formatDateTime(group[0].created_at) }}
              </p>
              <i class="bx bx-right-arrow-alt"></i>
              <p class="name mb-0">
                <span class="text-primary">{{
                  group[0].causer ? group[0].causer.name : "System"
                }}</span>
                updated this title
              </p>
              <i :class="`bx bx-chevron-${collapse[key] ? 'up' : 'down'}`"></i>
            </div>

            <b-collapse
              class="details"
              :id="`collapse-${key}`"
              v-model="collapse[key]"
            >
              <b-list-group class="pt-2">
                <b-list-group-item
                  v-for="(change, i) in group"
                  :key="i"
                  variant="light"
                  :class="{ 'py-1': true, 'un-ack': change.un_ack }"
                  v-html="change.description"
                >
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
          </li>
        </ul>
      </template>
      <p class="text-center" v-else>
        No activity
      </p>
      <div class="text-center mt-5 pt-3" v-if="showLoadMore">
        <ebp-button @click="get(true)" :loading="loadingMore"
          >Load older</ebp-button
        >
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import { groupBy } from "lodash-es";
import { isAfter, parseISO } from "date-fns";

export default {
  name: "changes-history",
  props: {
    id: Number,
    book: Object
  },
  data() {
    return {
      changesRaw: [],
      changes: null,
      collapse: {},
      loading: false,
      loadingMore: false,
      pagination: {
        perPage: 100,
        page: 1
      },
      showLoadMore: true,
      acknowledging: false
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async acknowledge() {
      this.acknowledging = true;

      try {
        await this.$store.dispatch("books/acknowledgeChanges", this.book.id);
        this.$emit("acknowledged", this.id);
      } catch (err) {
        console.error(err);
      }

      this.acknowledging = false;
    },
    formatDesc(desc) {
      return desc.replace(/•/g, "<br />");
    },
    async get(loadingMore = false) {
      const lastChangesPublishedAt = parseISO(
        this.book.last_changes_published_at || this.book.created_at
      );

      if (loadingMore) {
        this.loadingMore = true;
      } else {
        this.loading = true;
      }

      try {
        const res = await this.$store.dispatch("books/getChangesHistory", {
          id: this.id,
          pagination: this.pagination
        });
        let changes = [];
        res.data.data.forEach(change => {
          const changeCreatedDate = parseISO(change.created_at);
          if (change.description)
            change.description.split("•").forEach(changeDesc => {
              changes.push({
                ...change,
                description: changeDesc,
                un_ack: isAfter(changeCreatedDate, lastChangesPublishedAt)
              });
            });
        });
        this.changesRaw = this.changesRaw.concat(changes);

        if (res.data.data.length) {
          this.changes = groupBy(this.changesRaw, o => {
            const key =
              this.formatDateTime(o.created_at, "HHmmddMMMyyyy") + o.causer_id;
            Vue.set(this.collapse, key, false);
            return key;
          });
        }

        if (loadingMore && res.data.data.length) {
          this.collapse[Object.keys(this.collapse).reverse()[0]] = true;
        }

        if (res.data.current_page === res.data.last_page) {
          this.showLoadMore = false;
        }

        this.pagination.page++;
      } catch (err) {
        console.error(err);
      }

      this.loadingMore = false;
      this.loading = false;
    }
  },
  watch: {
    id(id) {
      if (id) {
        this.get();
      }
    }
  }
};
</script>

<style lang="scss">
.changes-history {
  ul.timeline {
    * {
      outline: none;
    }
    list-style-type: none;
    padding: 0;
    border-left: 2px dashed rgb(204, 204, 204);

    .timeline-item {
      position: relative;
      color: #333;
      width: 100%;
      cursor: pointer;

      .timeline-summary {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 0.8em;
        width: 100%;
        font-size: 14px;

        .bx {
          font-size: 1.3em;
        }

        .bx-right-arrow-circle {
          margin-left: -1.3rem;
          background: white;
          border-radius: 100%;
        }

        p.date {
          font-weight: 500;
          width: 170px;
          text-align: left;
          padding: 0 10px;
        }

        p.name {
          flex-grow: 1;
          padding-left: 10px;
          text-align: left;
        }

        .bx-right-arrow-alt {
          color: $primary;
        }
      }

      .details {
        width: 100%;

        .list-group {
          padding-top: 0.5rem !important;
          width: 96%;
          margin-left: 2%;
        }

        .list-group-item {
          border-radius: 0 !important;
          text-align: left;
          padding-left: 10px;
          font-size: 12px;
          color: #000;

          &:first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .un-ack {
    background: $warning;
    color: white;
  }
}
</style>
