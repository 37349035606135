<template>
  <div class="book-form">
    <div class="email-leftbar card">
      <!-- User only buttons -->
      <template v-if="!isAdmin">
        <ebp-button
          variant="dark"
          class="mb-2"
          @click="validateSubmit"
          v-if="
            book.id &&
              (book.status === 'draft' || book.status === 'unpublish-requested')
          "
          >{{ $t("submit-for-publishing") }}</ebp-button
        >
        <ebp-button
          variant="danger"
          class="mb-2"
          @click="unpublish"
          v-else-if="book.status === 'live'"
          >{{ $t("request-unpublish") }}</ebp-button
        >
      </template>

      <!-- Save -->
      <ebp-button :loading="saving" variant="primary" @click="save">{{
        $t("save")
      }}</ebp-button>

      <!-- Status -->
      <p :class="`mt-2 text-center text-${statusVariant(book.status)}`">
        {{ $t("status") }}: {{ $t(book.status) }}
      </p>

      <div class="mail-list mt-4">
        <b-tabs vertical tag="a" v-model="tabIndex">
          <b-tab v-for="tab in tabs" :key="tab.text">
            <template #title>
              <i :class="`mdi mdi-${tab.icon} mr-2`"></i>
              <span>{{ $t(tab.text) }}</span>
              <span class="ml-1 float-right" v-if="tab.badge"
                >({{ $t("badge") }})</span
              >
            </template>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <ebp-card class="email-rightbar mb-3 card">
      <error :err.sync="error" />
      <component
        :is="activeTab.component || activeTab.text"
        :book.sync="book"
        :handleUpdate="handleUpdate"
        :initiated="initiated"
        :validate="validate"
        :id="book.id"
      />

      <!-- Book pagination -->
      <b-row align-h="between" class="mt-5">
        <b-col>
          <ebp-button
            variant="dark"
            v-if="prevStep"
            @click="tabIndex = prevStep.index"
          >
            <i class="bx bx-left-arrow-alt"></i>
            {{ $t(prevStep.text) }}
          </ebp-button>
        </b-col>
        <b-col class="text-right" v-if="nextStep">
          <ebp-button variant="dark" @click="tabIndex = nextStep.index">
            {{ $t(nextStep.text) }}
            <i class="bx bx-right-arrow-alt"></i>
          </ebp-button>
        </b-col>

        <!-- Save & Submit book -->
        <b-row v-else class="mr-1">
          <b-col>
            <span v-if="!isAdmin" class="mr-2">
              <ebp-button
                size="sm"
                variant="dark"
                @click="validateSubmit"
                v-if="
                  book.id &&
                    (book.status === 'draft' ||
                      book.status === 'unpublish-requested')
                "
                >{{ $t("submit-for-publishing") }}</ebp-button
              >
            </span>
            <ebp-button
              size="sm"
              :loading="saving"
              variant="primary"
              @click="save"
              >{{ $t("save") }}</ebp-button
            >
          </b-col>
        </b-row>
      </b-row>
    </ebp-card>

    <!-- Submmission Modal -->
    <ebp-modal :open.sync="showSubmitModal" :tabs="['submit-for-publishing']">
      <template #submit-for-publishing>
        <submission
          :book="book"
          :handleUpdate="handleUpdate"
          @submit="submit"
        />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import SelectUser from "./select-user";
import BasicDetails from "./basic-details";
import PublishingDetails from "./publishing-details";
import Contributors from "./contributors";
import SalesAndPricing from "./sales-and-pricing";
import Categories from "./categories";
import Aigenerated from "./aigenerated";
import AdminFields from "./admin-fields";
import Territories from "./territories";
import ChangeHistory from "@/views/admin/books/changes-history";

import bookHelper from "@/helpers/book";
import priceHelper from "@/helpers/price";
import { cloneDeep } from "lodash-es";
import { mapState } from "vuex";
import Submission from "@/components/book-form/Submission";

// Validation schemas
import submitBookSchema from "@/validation-schemas/submitBook";
import saveBookSchema from "@/validation-schemas/saveBook";
import { format } from "date-fns";

export default {
  name: "book-form",
  props: {
    useBook: Object,
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SelectUser,
    BasicDetails,
    PublishingDetails,
    Contributors,
    SalesAndPricing,
    Categories,
    Aigenerated,
    Submission,
    AdminFields,
    Territories,
    ChangeHistory
  },
  data() {
    return {
      book: {},
      tabIndex: 0,
      initiated: false,
      validate: false,
      error: null,
      saving: false,
      showSubmitModal: false
    };
  },
  mounted() {
    // Get necessary resources
    this.$store.dispatch("ancillaries/getLanguages");
    this.$store.dispatch("ancillaries/getBookCategories");
    this.$store.dispatch("ancillaries/getSalesChannels");
    this.$store.dispatch("ancillaries/getCurrencies");
    this.$store.dispatch("ancillaries/getTerritories");
    this.$store.dispatch("ancillaries/getValidCurrencies");

    // Workaround to fix CKEditor bug
    setTimeout(() => (this.initiated = true), 10);

    // If book not supplied, fallback to create mode
    if (this.$props.useBook) {
      this.book = this.$props.useBook;
    } else {
      this.book = bookHelper.convertFromAPIFormat(
        cloneDeep(this.$store.getters["books/fields"])
      );
      this.book.publication_date = format(Date.now(), "yyyy-MM-dd");
    }

    if (!this.$route.query.tab) {
      this.handleTabQuery();
    } else {
      this.activateTab(this.$route.query.tab);
    }

    if (this.territories.length) {
      this.book.territories = bookHelper.formatTerritories(
        this.book.territories
      );
    }
  },
  computed: {
    ...mapState("ancillaries", ["currencies", "salesChannels", "territories"]),
    activeTab() {
      return this.tabs[this.tabIndex];
    },
    tabs() {
      const tabs = [
        {
          text: "basic-details",
          icon: "account-details-outline",
          badge: null
        },
        {
          text: "publishing-details",
          icon: "book-open-outline",
          badge: null
        },
        {
          text: "contributors",
          icon: "account-circle-outline",
          badge: null
        },
        {
          text: "sales-and-pricing",
          icon: "cash-usd-outline",
          badge: null
        },
        {
          text: "territories",
          component: "territories",
          icon: "map-outline",
          badge: null
        },
        {
          text: "categories-and-keywords",
          component: "categories",
          icon: "shape-outline",
          badge: null,
          className: "categories-and-keywords"
        },
        {
          text: "ai-generated",
          component: "aigenerated",
          icon: "shape-outline",
          badge: null,
          className: "ai-generated-content"
        }
      ];

      if (this.$props.isAdmin) {
        tabs.push({
          text: "admin-fields",
          icon: "cog",
          badge: null
        });
        if (this.book.id) {
          tabs.push({
            text: "change-history",
            icon: "history",
            badge: null
          });
        }
        tabs.unshift({
          text: "select-client",
          component: "select-user",
          icon: "account",
          badge: null
        });
      }

      return tabs;
    },
    nextStep() {
      if (this.tabIndex === this.tabs.length - 1) {
        return null;
      }

      return {
        ...this.tabs[this.tabIndex + 1],
        index: this.tabIndex + 1
      };
    },
    prevStep() {
      if (!this.tabIndex) {
        return null;
      }

      return {
        ...this.tabs[this.tabIndex - 1],
        index: this.tabIndex - 1
      };
    }
  },
  methods: {
    handleUpdate(el, value) {
      if (el.key.includes("price-")) {
        priceHelper.updatePrices(el.key, value, this.book);
      }

      if (el.key === "book_type") {
        this.book.list_for_free =
          el.value === "ebook" ? this.book.list_for_free : false;
      }

      // Update key
      this.book[el.key] = value;
    },
    handleTabQuery() {
      const tab = this.activeTab;
      if (tab && tab.text !== this.$route.query.tab) {
        this.$router.push({ query: { tab: tab.text } });
      }
    },
    activateTab(tab) {
      if (this.tabs.map(t => t.text).includes(tab)) {
        setTimeout(
          () => (this.tabIndex = this.tabs.map(t => t.text).indexOf(tab)),
          100
        );
      }
    },
    async save() {
      this.error = "";
      const book = this.book;
      const validation = saveBookSchema.validate(book, this.joiOptions);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        // Prepare currencies in array
        const bookAPIFormat = bookHelper.convertToAPIFormat(book);

        // Save book
        this.saving = true;

        try {
          const res = await this.$store.dispatch(
            book.id ? "books/update" : "books/create",
            bookAPIFormat
          );

          if (!book.id) {
            this.$router.push({
              name: this.$props.isAdmin ? "AdminEditBook" : "EditBook",
              params: {
                id: res.data
              }
            });
          }
        } catch (err) {
          console.error(this.errorRes(err));
        }

        this.saving = false;
      }
    },
    validateSubmit() {
      this.error = "";

      const book = this.book;
      const validation = submitBookSchema.validate(book, this.joiOptions);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.showSubmitModal = true;
      }
    },
    async submit() {
      this.showSubmitModal = false;
      try {
        await this.$store.dispatch("books/submit", this.book);
        this.book.status = "in-review";
        await this.$router.push({ name: "ViewBooks" });
      } catch (err) {
        console.error(err);
      }
    },
    async unpublish() {
      try {
        // Save book
        this.saving = true;
        await this.$store.dispatch("books/requestUnpublish", this.book.id);
        this.book.status = "unpublish-requested";
        this.saving = false;
      } catch (err) {
        console.error(err);
      }
    }
  },
  watch: {
    activeTab() {
      this.handleTabQuery();
    },
    useBook(book) {
      this.book = book;
    },
    currencies(currencies) {
      if (currencies.length) {
        this.book = priceHelper.addPriceProps(this.book);
      }
    },
    territories(territories) {
      if (territories.length) {
        this.book.territories = bookHelper.formatTerritories(
          this.book.territories
        );
      }
    },
    salesChannels(salesChannels) {
      if (salesChannels) {
        if (typeof this.book.sales_channels[0] === "object") {
          this.book.sales_channels = bookHelper.groupChannelIdsByCategory(
            this.book.sales_channels
          );
        }
      }
    }
  }
};
</script>

<style lang="scss">
.book-form {
  .pagination {
    padding-left: 1em;
  }

  i.mdi {
    font-size: 16px;
  }
}
</style>
