<template>
  <div class="territories">
    <h5 class="mb-4">{{ $t("territories") }}</h5>
    <b-overlay :show="territories.length === 0">
      <FormGenerator
        :elements="elements"
        :handleUpdate="handleUpdate"
        :data="book"
        inline
        v-bind="$attrs"
      />
    </b-overlay>
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";
import { mapState } from "vuex";

export default {
  name: "territories",
  components: {
    FormGenerator
  },
  props: {
    book: {
      type: Object,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapState("ancillaries", ["territories"]),
    elements() {
      return [
        {
          key: "distribute_worldwide",
          label: "distribute-worldwide",
          type: "switch"
        },
        {
          key: "territory_include_exclude",
          label: "Territory selection",
          type: "select",
          attrs: {
            options: [
              {
                text: "Only distribute to territories selected below",
                value: "include"
              },
              {
                text: "Distribute everywhere except territories selected below",
                value: "exclude"
              }
            ]
          },
          hideIf: "distribute_worldwide"
        },
        {
          key: "territories",
          label: "territories",
          type: "tree-select",
          placeholder: "Select territories",
          className: "mb-4",
          attrs: {
            options: this.territories,
            label: "name",
            trackBy: "id",
            loading: !this.territories.length
          },
          hideIf: "distribute_worldwide"
        }
      ];
    }
  }
};
</script>

<style></style>
