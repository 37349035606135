import Joi from "joi";
import errorMessages from "./errorMessages.json";
import isbnHelper from "./custom/isbn";
import BookTypes from "@/fixtures/book-types";
import contributors from "./custom/contributors";
import store from "@/store";
const currencies = store.getters["ancillaries/currencies"];
const validCurrencies = store.getters["ancillaries/validCurrencies"];

const priceKeys = currencies
  .filter(c => validCurrencies.includes(c.id))
  .map(c => ({ key: `price-${c.id}` }));

const priceValidation = {};
for (const priceKey of priceKeys) {
  priceValidation[priceKey.key] = Joi.when("list_for_free", {
    is: false,
    then: Joi.disallow(null, "", "0.00", 0.0).required(),
    otherwise: Joi.optional()
  });
}

export default Joi.object({
  book_type: Joi.string().valid(...BookTypes),
  title: Joi.string().max(80),
  subtitle: Joi.string()
    .max(200)
    .allow(null, ""),
  sales_description: Joi.string()
    .max(2 * 2e9)
    .required(),
  is_part_of_series: Joi.allow(0, 1, true, false),
  is_adult_only: Joi.allow(0, 1, true, false),
  series_name: Joi.when("is_part_of_series", {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.optional()
  }),
  series_number: Joi.when("is_part_of_series", {
    is: true,
    then: Joi.number().required(),
    otherwise: Joi.optional()
  }),
  primary_language_id: Joi.invalid(0, null),
  page_size: Joi.when("book_type", {
    is: "print",
    then: Joi.required().invalid(0, null),
    otherwise: Joi.optional()
  }),
  interior_and_paper_type: Joi.when("book_type", {
    is: "print",
    then: Joi.required().invalid(0, null),
    otherwise: Joi.optional()
  }),
  cover_finish: Joi.when("book_type", {
    is: "print",
    then: Joi.required().invalid(0, null),
    otherwise: Joi.optional()
  }),
  keywords: Joi.array().min(1),
  publisher_name: Joi.string()
    .max(200)
    .required(),
  publication_date: Joi.date().required(),
  page_count: Joi.when("book_type", {
    is: "ebook",
    then: Joi.number().min(1),
    otherwise: Joi.optional()
  }),
  image_count: Joi.when("book_type", {
    is: "ebook",
    then: Joi.number().optional(),
    otherwise: Joi.optional()
  }),
  has_own_isbn: Joi.boolean().allow(0, 1),
  epub_isbn: Joi.when("has_own_isbn", {
    is: true,
    then: Joi.when("book_type", {
      is: "ebook",
      then: Joi.custom(isbnHelper),
      otherwise: Joi.optional()
    }),
    otherwise: Joi.optional()
  }),
  print_isbn: Joi.when("has_own_isbn", {
    is: true,
    then: Joi.when("book_type", {
      is: "print",
      then: Joi.custom(isbnHelper),
      otherwise: Joi.optional()
    }),
    otherwise: Joi.optional()
  }),
  has_drm: Joi.allow(0, 1, true, false),
  distribute_worldwide: Joi.allow(0, 1, true, false),
  authors: Joi.custom(contributors),
  sales_channels: Joi.when("book_type", {
    is: "ebook",
    then: Joi.array().min(1),
    otherwise: Joi.optional()
  }),
  territories: Joi.when("distribute_worldwide", {
    is: false,
    then: Joi.array().min(1),
    otherwise: Joi.optional()
  }),
  list_for_free: Joi.boolean().allow(0, 1),
  categories: Joi.array().min(1),
  notes: Joi.string()
    .allow(null, "")
    .max(400),
  enrol_in_amazon_kdp_select: Joi.allow(0, 1, true, false),
  send_to_new_channels_in_network: Joi.allow(0, 1, true, false),
  publish_asap: Joi.boolean().allow(null),
  image_ai_tools: Joi.when("ai_image", {
    not: null,
    then: Joi.array().min(1)
  }),
  text_ai_tools: Joi.when("ai_text", {
    not: null,
    then: Joi.array().min(1)
  }),
  translation_ai_tools: Joi.when("ai_translation", {
    not: null,
    then: Joi.array().min(1)
  }),
  ...priceValidation
})
  .unknown()
  .messages(errorMessages);
