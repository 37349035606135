<template>
  <div class="categories">
    <h5 class="mb-4">{{ $t("categories-and-keywords") }}</h5>
    <FormGenerator
      :elements="elements"
      :handleUpdate="handleUpdate"
      :data="book"
      inline
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";
import { mapState } from "vuex";

export default {
  name: "categories",
  components: {
    FormGenerator
  },
  props: {
    book: {
      type: Object,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      searchTerm: ""
    };
  },
  computed: {
    ...mapState("ancillaries", { categories: "bookCategories" }),
    foundCategories() {
      const q = this.searchTerm;
      if (q) {
        try {
          let searchTermArray = q.split(" ");
          let finalCategories = [];
          this.categories.forEach(catName => {
            let foundCats = {};

            searchTermArray.forEach(term => {
              if (catName.name.toLowerCase().includes(term.toLowerCase())) {
                foundCats[term] = true;
              }

              if (catName.code.toLowerCase().includes(term.toLowerCase())) {
                foundCats[term] = true;
              }
            });

            if (Object.keys(foundCats).length >= searchTermArray.length) {
              finalCategories.push(catName);
            }
          });

          return finalCategories;
        } catch (err) {
          console.error(err);
        }
      }

      return this.categories;
    },
    elements() {
      return [
        {
          key: "categories",
          label: "Categories (3 Max)",
          type: "multiselect",
          placeholder: "Search for name or enter BISAC code",
          className: "mb-4",
          attrs: {
            internalSearch: false,
            limit: 3,
            limitText() {
              return "Only 3 categories can be selected";
            },
            options: this.foundCategories,
            customLabel({ name, code }) {
              return `${name} (${code})`;
            },
            trackBy: "code",
            loading: !this.foundCategories.length
          },
          events: {
            "search-change": this.search
          }
        },
        {
          key: "is_adult_only",
          label: "adult-only-content",
          type: "switch"
        },
        {
          key: "reading_age_from",
          label: "reading-age-from",
          type: "number",
          showIf: [["book_type", "ebook"], this.isChildrensBook],
          hideIf: "is_adult_only"
        },
        {
          key: "reading_age_to",
          label: "reading-age-to",
          type: "number",
          showIf: [["book_type", "ebook"], this.isChildrensBook],
          hideIf: "is_adult_only"
        },
        {
          key: "keywords",
          label: "Keywords (8 max)",
          type: "tags",
          placeholder: "Enter keywords/phrases individually and click Add",
          attrs: {
            options: [this.book.keywords],
            limit: 8,
            limitTagsText: this.$t("validation-errors.max-keywords")
          }
        }
      ];
    }
  },
  methods: {
    isChildrensBook() {
      return !!this.book.categories.filter(category => {
        return ["JUV", "JNF", "YAF", "YAN"].includes(
          category.code.substring(0, 3)
        );
      }).length;
    },
    search(term) {
      this.searchTerm = term;
    }
  }
};
</script>

<style></style>
