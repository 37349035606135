<template>
  <div class="basic-details">
    <h5 class="mb-4">{{ $t("sales-and-pricing") }}</h5>
    <FormGenerator
      :elements="elements"
      :handleUpdate="handleUpdate"
      :data="book"
      inline
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";
import { mapGetters, mapState } from "vuex";
import bookHelper from "@/helpers/book";

export default {
  name: "basic-details",
  components: {
    FormGenerator
  },
  props: {
    book: {
      type: Object,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapState("ancillaries", {
      salesChannelsRaw: "salesChannelsCategorized",
      currencies: "currencies",
      validCurrencies: "validCurrencies"
    }),
    ...mapGetters("auth", ["user"]),
    salesChannels() {
      return bookHelper.optionizeChannels(this.salesChannelsRaw);
    },
    eligibleSalesChannels() {
      const channels = [];

      this.salesChannels.forEach(c => {
        const children = c.children.filter(c => {
          if (c.name.toLowerCase().includes("(print)")) {
            return false;
          }

          if (c.category === "legacy") {
            return false;
          }

          return (
            (!this.book.list_for_free || !!c.does_allow_free_listing) &&
            (!this.book.accepts_promos || !!c.does_allow_promos) &&
            (!this.book.enrol_in_amazon_kdp_select ||
              !!c.name.toLowerCase().includes("amazon"))
          );
        });

        if (children.length) {
          channels.push({
            ...c,
            children
          });
        }
      });

      return channels;
    },
    elements() {
      return [
        {
          key: "enrol_in_amazon_kdp_select",
          label: "enrol-in-amazon-kdp-select",
          type: "switch",
          showIf: [["book_type", "ebook"]]
        },
        {
          key: "list_for_free",
          label: "list-for-free",
          type: "switch",
          showIf: [
            ["book_type", "ebook"],
            ["enrol_in_amazon_kdp_select", false]
          ]
        },
        {
          key: "sales_channels",
          label: "sales-channels",
          type: "tree-select",
          attrs: {
            options: this.eligibleSalesChannels,
            loading: this.$store.state.ancillaries.salesChannelsLoading,
            expandLevel: 1
          },
          showIf: [["book_type", "ebook"]]
        },
        {
          key: "send_to_new_channels_in_network",
          label: "send-to-new-channels-in-network",
          type: "switch",
          showIf: [
            ["book_type", "ebook"],
            ["enrol_in_amazon_kdp_select", false]
          ]
        },
        {
          text: this.$t("exchange-rate-last-updated", {
            updated: this.fromNow(
              this.currencies.length
                ? this.currencies
                    .slice(0)
                    .sort((a, b) => b.is_primary - a.is_primary)[0]
                    .exchange_rate_last_updated
                : ""
            )
          }),
          type: "paragraph",
          className: "mt-3"
        },
        {
          key: "base_currency",
          label: "base-currency",
          type: "select",
          className: "mb-5",
          attrs: {
            options: this.currencies
              .filter(c => this.validCurrencies.includes(c.id))
              .map(c => ({
                text: c.title,
                value: c.code
              }))
          }
        }
      ].concat(
        this.currencies
          .filter(c => this.validCurrencies.includes(c.id))
          .slice(0)
          .sort((a, b) =>
            b.code === this.book.base_currency
              ? 0
              : a.code === this.book.base_currency
              ? -1
              : 1
          )
          .map(c => ({
            key: `price-${c.id}`,
            label: this.$t("price-in", { currency: c.code }),
            type: "number",
            placeholder: c.title,
            hideIf: "list_for_free",
            attrs: {
              step: "0.01"
            }
          }))
      );
    }
  },
  methods: {
    filterSelectedSalesChannelsBasedOnEligible() {
      const eligibleChannelIds = this.eligibleSalesChannels.map(c => c.id);
      this.$props.handleUpdate(
        { key: "sales_channels" },
        this.$props.book.sales_channels.filter(c =>
          eligibleChannelIds.includes(c)
        )
      );
    }
  },
  watch: {
    "$props.book.list_for_free"() {
      this.filterSelectedSalesChannelsBasedOnEligible();
    },
    "$props.book.enrol_in_amazon_kdp_select"() {
      this.filterSelectedSalesChannelsBasedOnEligible();
    }
  }
};
</script>

<style></style>
